@use 'sass:math';
@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/variables.scss' as v;

@import '@danfoss/mosaic-css/dist/components/checkbox.css';

.ui-checkbox {
    align-items: center;
    user-select: none;
    align-items: flex-start;

    .df-checkbox {
        align-items: center;
    }

    ._extra-spacing {
        margin: get-spacing(2) 0;
    }

    .label-container {
        // Removes added negative margin from mosaic, that offsets the text from the checkbox
        margin-top: 0px !important;
    }

    span {
        display: block;
    }

    .label {
        font-weight: 600;
        display: flex;
    }

    .description {
        color: cssvar(color-object-base);
    }

    ._disabled .description {
        color: cssvar(color-neutral-base);
    }

    ._hidden {
        display: none;

        & + .label-container {
            padding-left: 0;
        }
    }
}


:global(.df-mosaic .df-checkbox:not(#\#) input[type="checkbox"]) {
  border: 2px solid cssvar(color-neutral-stronger) !important;
}

:global(.df-mosaic .df-checkbox:not(#\#) input[type="checkbox"]:checked) {
  border-color: cssvar(color-neutral-stronger) !important;
}