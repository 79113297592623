@use "@danfoss/mosaic-sass/dist/entry" as *;

.icon {
  animation: spin 1s linear infinite;
  fill: cssvar(color-secondary);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
