@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins.scss";

:global(.df-mosaic :not(#\#)) .chat-input {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include mixins.chat-max-width;

  .settings-container {
    width: 100%;
    margin-bottom: get-spacing(2);

    :global(.df-mosaic .df-select-menu) {
      margin-bottom: get-spacing(2);
      top: unset !important;
      bottom: 100% !important;
    }

    .accordion {
      margin-bottom: get-spacing(3);
    }

    .persona-note {
      min-width: 100%;
      margin-top: 0;
      margin-bottom: get-spacing(2);
    }

    @include match-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0 get-spacing(6);
      margin-bottom: get-spacing(2);

      > * {
        flex: 1;
      }
    }
  }

  .llm-description {
    margin-top: get-spacing(1);
    margin-bottom: get-spacing(3);
    display: block;
  }

  .container {
    flex: 1;

    .textarea {
      flex: 1;
      resize: none;
      max-height: 134px; // Will allow for 5 lines of text
      overflow: auto;

      &::placeholder,
      &::-ms-input-placeholder {
        color: cssvar(color-object-base);
      }

      &:disabled {
        opacity: 1 !important; // override iOS opacity for disabled inputs
      }
    }

    .upload-icon-wrapper {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }

    .cta-icon-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: get-spacing(1.5);
      align-items: flex-end;
      padding-bottom: get-spacing(2);
      padding-right: get-spacing(1);

      [data-documents-quantity]:after {
        content: attr(data-documents-quantity);
        font-family: "myriad-pro", "Source Sans Pro", sans-serif, system-ui;
        font-weight: bold;
        font-size: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        top: -6px;
        left: 10px;

        min-width: 13px;
        min-height: 13px;
        border-radius: 50%;

        line-height: 1;
        background-color: cssvar(color-component-navigation-border);
        border: 2px solid cssvar(color-component-navigation-border);
        color: cssvar(color-primary);
      }

      [data-documents-quantity="0"]:after {
        content: none;
      }
    }
  }

  .disclaimer-note {
    font-size: 0.6rem;
    line-height: 1.4;
  }

  @include match-breakpoint-down(sm) {
    .disclaimer-note {
      text-align: center;
    }
  }
}

// In modals
:global(.df-mosaic) .accordion-desc {
  margin-bottom: get-spacing(3);
}

.settings-personas {
  min-width: 100%;
}

.settings-personas-options {
  padding: 10px 0; // allow space for beta badge to not overflow and for the scrollbar on mac not to overlay the content
  display: grid;
  gap: get-spacing(4);
  grid-template-columns: repeat(2, 1fr);

  @include match-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

  @include mixins.thin-scroll;

  button span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .persona-card {
    position: relative;

    &.beta:after {
      content: attr(data-beta-label);
      padding: 5px;
      border-radius: 3px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(30%, -50%);
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: cssvar(color-primary);
      background-color: cssvar(color-accent-weak);
    }
  }
}

@include match-breakpoint-down(xs) {
  .accordion {
    margin-top: get-spacing(2);
  }
}
