@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins.scss";

.list:not(#\#) {

    a,
    li {
        width: 100%;
        text-decoration: none !important;
    }

    &._disabled .list-item,
    &._disabled .list-item > * {
        cursor: not-allowed !important;
        color: cssvar(color-object-weaker) !important;
    }
}

.active {
    background-color: cssvar(color-surface-base);

    span {
        transform: none;
    }

    .pinning-icon:before {
        background-color: cssvar(color-surface-base) !important;
    }
}

.list-item {
    min-height: 50px;
    width: 100%;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    transition: background-color 0.35s ease;


    div.inner {
        width: 100%;
        display: flex;
        align-items: center;
        gap: get-spacing(2);
    }

    .icon {
        flex-shrink: 0;
        display: flex;
    }

    .title {
        flex: 1;
    }

    .chip {
        opacity: 0;
        margin-left: auto;
        padding: 4px 6px;
        transition: background 100ms ease-in-out;
        background-color: cssvar(color-primary) !important;

        &:not(:hover) {
            border-color: transparent;
            background-color: transparent !important;
        }

        span {
            color: cssvar(color-accent-base);
            transition: 100ms ease-in-out;
        }

        @include mixins.mobileDevice() {
            opacity: 1;
        }
    }

    &:hover .chip {
        opacity: 1;
        pointer-events: all;
    }
}