@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/color.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as mixins;

@mixin color($color) {
  $transition: 0.1s ease-in-out;

  @if ($color ==inherit) {
    color: inherit;
    fill: inherit;
  } @else {
    transition: $button-color-transition;
    color: $color;
    fill: $color;
  }
}

$sizes: (16, 18, 20, 22, 24, 42, 54, 96, 300);

$themes: (
  // Matches mosaic theme (red/darkred)
  inherit:
    (
      color: inherit,
      hover: inherit,
    ),
  dark: (
    color: cssvar(color-secondary),
    hover: cssvar(color-text-strong),
  ),
  light: (
    color: cssvar(color-secondary),
    hover: cssvar(color-object-weaker),
  ),
  accent: (
    color: cssvar(color-accent-base),
    hover: lighten(rgb(226, 0, 15), 10%),
  ),
  // Custom themes
  success:
    (
      color: cssvar(color-success),
      hover: lighten(rgb(20, 197, 16), 10%),
    ),
  error: (
    color: cssvar(color-error),
    hover: lighten(rgb(226, 0, 15), 10%),
  ),
  warning: (
    color: cssvar(color-warning),
    hover: lighten(rgb(255, 170, 0), 10%),
  ),
  info: (
    color: cssvar(color-info),
    hover: lighten(rgb(70, 185, 243), 10%),
  )
);

$border-size: (
  0: 0,
  1: 1,
  2: 2,
);

.icon {
  user-select: none;
  overflow: hidden; // Allows to see if icons are formatted incorrectly

  // Sizes
  @each $size in $sizes {
    &.size-#{$size} {
      line-height: 1; // Prevent icons from inheriting line-height
      font-size: #{$size}px;
      // For SVG icons
      width: #{$size}px;
      height: #{$size}px;
    }
  }

  // Themes
  @each $theme, $map in $themes {
    &.theme-#{$theme} {
      $c: map-get($map, color);
      $h: map-get($map, hover);
      @include color($c);

      &.clickable {
        cursor: pointer;

        &:hover {
          @include color($h);
        }
      }

      &.loader {
        @include color($c);
      }
    }
  }

  @each $class, $size in $border-size {
    &.border-size-#{$class} {
      border-radius: 50%;
      padding: get-spacing(1);
      border: #{$size}px solid;
      overflow: hidden;
    }
  }

  @include loop-colors("border-color", "border-color");
  @include loop-colors("color", "fill");

  &[aria-disabled="true"] {
    @include color(cssvar(color-neutral-strong));
    cursor: not-allowed;
  }
}

.icon-selector {
  position: relative;
  overflow: hidden;
  max-width: 100%;

  .content {
    padding: get-spacing(2);
    border: 1px solid cssvar(color-neutral-base);
    border-radius: 5px;
    display: flex;
    gap: get-spacing(2);
    overflow-y: auto;
    @include mixins.thin-scroll;
  }

  .icon-wrapper {
    cursor: pointer;
    min-width: 46px;
    min-height: 46px;
    padding: get-spacing(2);
    border-radius: 5px;
    border: 1px solid cssvar(color-surface-base);
    background-color: cssvar(color-surface-base);
    transition: background-color $transition-sm;

    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      pointer-events: none;
    }

    &.selected {
      background-color: cssvar(color-neutral-base);
    }

    &.new-selected {
      border-color: cssvar(color-secondary);
    }

    &:hover {
      background-color: cssvar(color-neutral-base);
    }
  }

  &.disabled-selector {
    .icon-wrapper:hover {
      cursor: not-allowed;
      background-color: cssvar(color-neutral-weak);
    }
  }
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  background-color: cssvar(color-surface-base);
  padding: get-spacing(5);

  &.full-width {
    display: block;
    width: 100%;
  }

  &.placeholder > span {
    color: cssvar(color-neutral-strong);
    fill: cssvar(color-neutral-strong);
  }
}
