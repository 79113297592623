@use "@danfoss/mosaic-sass/dist/entry" as *;

.file-drop-content {
    display: flex;
    align-items: center;
    justify-content: center;

    [aria-disabled='true'] {
        cursor: not-allowed !important;
        color: cssvar(color-neutral-base) !important;
    }
}

.df-file-drop {
    align-items: center;
    border: 2px dashed cssvar(color-neutral-strong);
    display: flex;
    justify-content: center;
    min-height: 136px;

    &--dragging {
        border-color: cssvar(color-secondary) !important;
    }

    &[aria-disabled='true'] {
        cursor: not-allowed !important;
        color: cssvar(color-neutral-base);
        border-color: cssvar(color-neutral-base);
    }
}