@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use './../../styles/mixins.scss';
@use './../../styles/mosaic.scss';

@include mosaic.override-specificity-module {
    .chat-window-header {
        box-shadow: 0 0 5px -2px cssvar(color-primary);


        input {
            background-color: transparent;
            border: 0;
            padding: get-spacing(2) 0;
            text-align: center;
            flex-grow: 1;
            font-size: 18px;
            font-weight: 600;
            margin-left: auto;
            margin-right: auto;
            color: cssvar(color-secondary);
            width: 100%;

            @include mixins.ellipsis(1);

            &:disabled {
                pointer-events: all;
                color: cssvar(color-secondary);
                -webkit-text-fill-color: cssvar(color-secondary);
                opacity: 1;
                /* required on iOS */
            }
        }

        .input-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .loader {
            cursor: default;

            svg {
                display: block;
            }
        }

        // Mobile vs desktop
        .mobile {
            display: initial;
        }

        .desktop {
            display: none;
        }

        @include match-breakpoint-up(sm) {
            .mobile {
                display: none;
            }

            .desktop {
                display: initial;
            }
        }

        // Icon positioning
        .center {
            grid-column: var(--grid-position) / span 1;
            --grid-position: 2;
        }
    }
}