@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "./../../styles/variables.scss" as v;
@use "../../styles/mixins" as mixins;

@mixin dragHandleStyling {
  .handle {
    right: get-spacing(2);
    position: absolute;

    &::before {
      content: "";
      position: absolute;
      inset: -8px;
      padding: 4px;
      background: cssvar(color-surface-base);

      > span {
        z-index: 1;
      }
    }
  }

  @include mixins.mobileDevice {
    &:not(.selected) .handle::before {
      background: cssvar(color-primary);
    }
  }
}

@mixin iconChipStyling {
  position: absolute;
  z-index: 2;
  opacity: 0;

  &::before {
    content: "";
    background-color: cssvar(color-primary);
    position: absolute;
    height: 100%;
    width: 100%;
    inset: -3px;
    padding: 4px;
    z-index: -1;
  }

  @include mixins.mobileDevice {
    opacity: 1;
  }
}

.persona-preferences {
  position: relative;
  border-bottom: 1px solid cssvar(color-neutral-weak);
  max-height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.list {
  background-color: cssvar(color-primary);
  overflow: auto;
  @include mixins.thin-scroll;
}

.group-title {
  padding: get-spacing(3) get-spacing(4);
  position: sticky;
  display: block;
  top: 0;
  font-weight: 700;
  background-color: cssvar(color-primary);
}

.list li {
  span:last-of-type {
    max-width: 100%;
    transition: transform 150ms ease-in-out;
    @include mixins.ellipsis(1);
  }

  &:hover span:last-of-type:not(:global(.df-chip) span) {
    transform: translateX(get-spacing(1.5));
  }
}
.open-catalog {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: cssvar(color-primary);
}

// remember that it is outside of list when dragged
ul {
  position: relative;
}

ul:has(.list-item:not(.isActive)) {
  @include mixins.dropZoneHighlighter();
}

ul:has(.list-item.isActive) {
  @include mixins.dropZoneHighlighter(300ms, 1);
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 100%;
    flex: 1;

    // Target
    :global(.df-menu-item) {
      min-height: v.$sub-header-height-desktop;
    }

    @include match-breakpoint-down(sm) {
      :global(.df-menu-item) {
        min-height: v.$sub-header-height-mobile;
      }
    }
  }

  font-weight: bold;
  line-height: 1;

  &.isActive {
    opacity: 0;
  }

  .pinning-icon {
    right: get-spacing(10);
    @include iconChipStyling;
  }

  &:hover .pinning-icon {
    opacity: 1;

    &:before {
      background: cssvar(color-surface-base) !important;
    }
  }

  @include dragHandleStyling;

  &:hover .handle {
    opacity: 1;
  }

  &.isDragged {
    opacity: 0.5;
    background-color: cssvar(color-neutral-weak);
    cursor: grabbing;

    .handle {
      opacity: 1;
      cursor: grabbing;
    }

    .pinning-icon {
      opacity: 0;
    }
  }
}

.handle {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: grab;

  @include mixins.mobileDevice() {
    opacity: 1;
  }

  .icon {
    transform: translateX(0) !important;
    cursor: inherit !important;
  }
}

.hidden {
  display: none !important;
}
