@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins.scss" as mixins;

.card {
    gap: get-spacing(3);
    padding: get-spacing(5) get-spacing(4) get-spacing(5) get-spacing(5);
    border-radius: 6px;
    display: flex;

    @include mixins.cardBgHoverEffect(false);

    .description {
        font-size: 14px;
        line-height: 1.5;
    }

    b + .description {
        margin-top: get-spacing(1.5);
    }


    .info {
        flex: 1;
    }

    &.clickable {
        cursor: pointer;

        @include mixins.cardBgHoverEffect(true);
    }

    &.selected {
        border-color: cssvar(color-object-weak);
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}