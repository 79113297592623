@use "sass:math" as math;
@use "@danfoss/mosaic-sass/dist/entry" as *;

.icon-link {
    text-decoration: none !important;
}

$spacing: get-spacing(7);

.wrapper {
    padding-bottom: $spacing;

    > :first-child {
        margin-bottom: $spacing;
    }

    > *:not(:first-child) {
        margin-top: $spacing;
        margin-bottom: $spacing;
    }

    .section {
        margin-top: get-spacing(0);
        margin-bottom: get-spacing(0);

    }

    :not(.section) + .section {
        border-top: 1px solid cssvar(color-neutral-base);
    }

    .table {
        :global(.collapsible) {

            ul {
                list-style-type: disc;
            }

            span {
                display: block;
                max-height: none;
                overflow: hidden;
            }

            > input {
                display: none;

                &:not(:checked) {
                    + span {
                        max-height: 50px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    + ul {

                        // We display the "show more" label if there are more than three items in the list. 
                        // when the check box isn't checked, we will hide 4th item and higher
                        li:nth-child(n + 4) {
                            display: none;
                        }
                    }
                }
            }

            > label {
                width: 30px;
                text-align: center;
                line-height: 5px;
                padding: get-spacing(.5) 0 get-spacing(3);
                display: inline-block;
                border-radius: 3px;
                background-color: rgba(black, 0.05);
                cursor: pointer;
                user-select: none;
            }
        }
    }

    .table + .section,
    .section + .section {
        border-top: 1px solid transparent;
    }

    > .table:has(+ .section) {
        margin-bottom: 0;
    }

    .accordion {
        border-bottom: 1px solid cssvar(color-neutral-base);
        border-top: 1px solid cssvar(color-neutral-base);

        > button {
            padding: get-spacing(6) 0 !important;
        }

        .section {
            &.trimmed {
                padding-top: 0;
                padding-bottom: 0;
            }

            &:first-child {
                //padding-top: 0;
            }

            &:last-child {
                //padding-bottom: 0;
            }

            + h5,
            + div {
                margin-top: get-spacing(4);
            }
        }
    }

    .accordion + .accordion > div,
    .section + .accordion > div {
        border-top: 0;
    }

    .accordion + .table {
        margin-bottom: 0;

        tr:last-of-type {
            border-bottom: 0;
        }
    }

    .accordion + .section {
        border-top: 0;
        margin-top: 0;
    }

    .accordion + * {
        margin-top: calc($spacing * 0.6);
    }

}

.error {
    margin-bottom: get-spacing(4);
}