@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins";
@use "../../styles/helpers.scss" as h;
@use "../../styles/variables.scss" as var;
@use "../new-chat-window/NewChatWindow.module.scss" as *;


$search-width: 600px;

.catalog {
    flex: 1;
    overflow-y: auto;

    @include mixins.thin-scroll;
    // Override 'chatWindowBaseStyling' justify-content
    justify-content: flex-start;

    .content {
        padding: 0 get-spacing(6) get-spacing(8) get-spacing(6);
    }

    .header {
        overflow: auto;
        // position: sticky;
        z-index: 1;
        // top: 0;
        margin: 0;
        background-color: cssvar(color-primary);
        padding-top: get-spacing(3.5);
        padding-bottom: get-spacing(4);

        p {
            font-size: 14px;
        }

        > *:not(:last-child) {
            margin-bottom: get-spacing(4);
        }
    }

    .notification {
        display: block;
        max-width: $search-width;
        margin: 0 auto;
    }
}

.container {
    container-name: catalog;
    container-type: inline-size;

    > b {
        display: block;
        align-items: center;
        gap: get-spacing(1);
        margin: get-spacing(8) 0 get-spacing(4) 0;
    }
}

.container-children {
    position: relative;
    flex-wrap: wrap;
    display: grid;
    width: 100%;
    gap: get-spacing(8);
    grid-template-columns: repeat(3, 1fr);

    @container catalog (max-width: 1023.5px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @container catalog (max-width: 599.5px) {
        grid-template-columns: 1fr;
    }
}

.search {
    width: 100%;
    max-width: $search-width;
    margin: 0 auto;
}

.personas-see-all {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: get-spacing(3);

    > button {
        font-size: 14px !important
    }
}

.welcome {
    max-width: 800px;
    margin: 0 auto;
    margin-top: get-spacing(12);
    padding: get-spacing(3) get-spacing(5);
}

.header-title {


    div input {
        transition: opacity 0.2s ease;
        opacity: 0 !important;
    }

    &.visible div input {
        transition: opacity 0.2s ease;
        opacity: 1 !important;
    }
}

.card {
    position: relative;
    @include mixins.cardBgHoverEffect();

    &.alpha,
    &.beta {
        &:after {
            content: attr(data-content);
            color: cssvar(color-primary);
            background-color: cssvar(color-accent-base);
            padding: get-spacing(1) get-spacing(2);
            position: absolute;
            top: -12px;
            right: -12px;
            font-size: 12px;
            font-weight: 700;
            border-radius: 4px;
        }
    }


    &.alpha {
        &:after {
            background-color: cssvar(color-success);
        }
    }

    &.beta {
        &:after {
            background-color: cssvar(color-accent-base);
        }
    }
}