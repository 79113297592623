@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../../../styles/mixins.scss' as mixins;

@mixin buttonColoring {
    background-color: cssvar(color-object-base);
    color: cssvar(color-primary) !important;
    opacity: 0.6;
    transition: opacity 0.35s ease;

    &:hover {
        opacity: 1 !important;
    }
}

// Add image styling
.container {
    position: relative;
    display: table;
    padding: get-spacing(4);
    margin: get-spacing(2) 0;
    padding-bottom: get-spacing(4);
    border-radius: 6px;
    border: 1px solid cssvar(color-object-weak);
    background-color: cssvar(color-primary);

    min-width: 120px;
    min-height: 80px;

    .image-expanding {
        border-radius: 6px 0 6px 0;
        opacity: 0;

        @include mixins.mobileDevice {
            opacity: 1;
        }
    }

    &:hover,
    &:focus {
        .image-expanding {
            opacity: 0.6;
        }
    }
}

.image-expanding {
    position: absolute;
    padding: 3px 20px;
    font-size: 14px;
    bottom: 0;
    right: 0;

    @include buttonColoring;
}


// Modal
.image-modal {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: cssvar(color-primary);
    z-index: 100;
}

.close {
    box-sizing: initial !important;
    position: absolute;
    right: get-spacing(4);
    top: get-spacing(4);
    padding: get-spacing(2);
    z-index: 10;
    border-radius: 0 4px 0;
    @include buttonColoring;
}